<template>
    <div data-scroll
         :data-scroll-id="name"
         v-observer
         class="text-layout-6">
        <div :class="{light:lightMode}" class="content-container">
            <div class="up">
                <img src="../../../assets/images/point-start.svg" class="image start" alt="">
                <img src="../../../assets/images/point-end.svg" class="image end" alt="">
                <div class="description" v-html="item.contentText"></div>
            </div>
            <div class="down">
                <div class="line"></div>
                <div class="title" v-html="item.contentTitle"></div>
            </div>
        </div>
    </div>
</template>

<script>
import first from 'lodash/first'

export default {
    props: {
        data: {
            type: Object,
            default: () => {
            }
        },
        config: {
            type: Object,
            default: () => {
            }
        },
        lightMode: {
            type: Boolean,
            default: false
        },
        name: {
            type: String,
            default: ''
        },
    },
    computed: {
        item() {
            return first(this.data?.list?.map(item => ({
                background: item.background_color,
                title: item.title,
                contentTitle: first(item?.content?.tabs)?.title,
                contentText: first(item?.content?.tabs)?.content,
                color: item.color1
            })))
        },
    },

}
</script>

<style lang="scss" scoped>
$ease-out: cubic-bezier(.15, .16, .37, 1);

.text-layout-6 /deep/ {
    padding: 144px 375px 0;
    @media only screen and (max-width: 1650px) and (min-width: 1440px) {
        padding: 144px 165px 0;
    }
    @media only screen and (max-width: 1439px) and (min-width: 1366px) {
        padding: 144px 128px 0;
    }
    @media only screen and (max-width: 1365px) and (min-width: 1024px) {
        padding: 89px 47px 0;
    }
    @media only screen and (max-width: 1023px) and (min-width: 768px) {
        padding: 89px 47px 0;
    }
    @media only screen and (max-width: 767px) {
        padding: 55px 16px 0;
    }

    .content-container {
        color: white;
        text-align: center;

        @media only screen and (max-width: 1650px) and (min-width: 1440px) {
            grid-gap: 100px;
            font-size: 18px;
        }
        @media only screen and (max-width: 1439px) and (min-width: 1024px) {
            font-size: 18px;
            grid-gap: 30px;
        }
        @media only screen and (max-width: 1023px) {
            grid-gap: 35px;
            font-size: 18px;
            flex-direction: column;
        }

        &.light {
            color: #0A141C !important;
        }

        .up {
            position: relative;

            .image {
                position: absolute;
                transition: transform .6s .1s $ease-out;

                &.start {
                    left: 0;
                    top: 0;
                    transform: rotate(-90deg);
                    @media only screen and (max-width: 1365px) and (min-width: 1024px) {
                        left: -5px;
                    }
                    @media only screen and (max-width: 767px) {
                        top: -8px;
                    }
                }

                &.end {
                    bottom: 45px;
                    right: 13px;
                    transform: rotate(90deg);
                    @media only screen and (max-width: 1365px) and (min-width: 1024px) {
                        bottom: 32px;
                        right: 0;
                    }
                    @media only screen and (max-width: 1023px) and (min-width: 768px) {
                        bottom: 42px;
                        right: 0;
                    }
                    @media only screen and (max-width: 767px) {
                        bottom: -10px;
                        right: -5px;
                    }
                }
            }

            .description {
                font-size: 34px;
                z-index: 12;
                position: relative;
                display: block;
                transition: color .4s $ease-out;
                line-height: 60px;

                @media only screen and (max-width: 1365px) and (min-width: 768px) {
                    font-size: 21px;
                    line-height: 50px;
                }
                @media only screen and (max-width: 767px) {
                    font-size: 16px;
                    line-height: 30px;
                }
            }
        }

        .down {
            margin-top: 34px;
            @media only screen and (max-width: 767px) {
                margin-top: 21px;
            }
            .line {
                width: 550px;
                height: 1px;
                background: #E5B676;
                margin: 0 auto;
                transform-origin: center;
                transform: scaleX(0);
                transition: transform .6s .1s $ease-out;
            }

            .title {
                font-size: 16px;
                display: block;
                margin-top: 34px;
                transform: translateY(30px);
                transition: transform .6s .1s $ease-out, color .4s $ease-out;
                @media only screen and (max-width: 767px) {
                    margin-top: 21px;
                }
                span {
                    color: #E5B676;
                }
            }
        }
    }
}

.text-layout-6.observed {
    .image {
        &.start, &.end {
            transform: rotate(0) !important;
        }
    }

    .down {
        .line {
            transform: scaleX(1);
        }

        .title {
            transform: translateY(0) !important;
        }
    }
}
</style>
